<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="getLeasingStep.step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getLeasingError.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('leasings:basicInformation')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getLeasingError.stepOne.validation,
                                ]"
                                editable
                                step="2"
                                >{{ $t('leasings:other') }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="
                                                $t(
                                                    'leasings:leasingInformation'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <v-text-field
                                            :label="`${$t(
                                                'leasings:leasingNumber'
                                            )}*`"
                                            v-model="
                                                getLeasingModal.leasingNumber
                                            "
                                            :error-messages="
                                                getLeasingError.stepOne.fields
                                                    .leasingNumber
                                            "
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            :label="`${$t('leasings:title')}*`"
                                            v-model="getLeasingModal.title"
                                            :error-messages="
                                                getLeasingError.stepOne.fields
                                                    .title
                                            "
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-combobox
                                            v-model="getLeasingModal.contact"
                                            :label="`${$t(
                                                'leasings:contact.default'
                                            )}*`"
                                            hint="Zacznij pisać..."
                                            @keyup="
                                                setContacts($event.target.value)
                                            "
                                            @input="assignID()"
                                            :hide-no-data="hideContacts"
                                            :items="getContactsInput.items"
                                            item-text="name"
                                            item-value="_id"
                                            return-object
                                            :error-messages="
                                                getLeasingError.stepOne.fields
                                                    .contact
                                            "
                                        >
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-combobox
                                            :label="`${$t(
                                                'leasings:representative'
                                            )}`"
                                            v-model="
                                                getLeasingModal.representative
                                            "
                                            @keyup="
                                                setRepresentatives(
                                                    $event.target.value
                                                )
                                            "
                                            :item-text="text"
                                            return-object
                                            append-outer-icon="mdi-account-plus"
                                            item-value="_id"
                                            :items="
                                                getRepresentativeInput.items
                                            "
                                            ><template
                                                slot="selection"
                                                slot-scope="data"
                                            >
                                                {{ data.item.name }}
                                                {{ data.item.lastname }}
                                            </template>
                                            <template
                                                slot="item"
                                                slot-scope="data"
                                            >
                                                {{ data.item.name }}
                                                {{ data.item.lastname }}
                                            </template>
                                            <template slot="append-outer">
                                                <v-menu
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    :close-on-click="false"
                                                    @input="
                                                        v =>
                                                            v ||
                                                            clearMiniModal()
                                                    "
                                                    max-width="300"
                                                    min-width="300"
                                                    origin="center"
                                                    v-model="showMenu"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on: menu,
                                                        }"
                                                    >
                                                        <v-tooltip top>
                                                            <template
                                                                v-slot:activator="{
                                                                    on: tooltip,
                                                                }"
                                                            >
                                                                <v-btn
                                                                    small
                                                                    icon
                                                                    :disabled="
                                                                        getLeasingModal.contact
                                                                            ? false
                                                                            : true
                                                                    "
                                                                >
                                                                    <v-icon
                                                                        v-on="{
                                                                            ...menu,
                                                                            ...tooltip,
                                                                        }"
                                                                        @click="
                                                                            showMenu = true
                                                                        "
                                                                        >mdi-plus</v-icon
                                                                    >
                                                                </v-btn>
                                                            </template>
                                                            <span>{{
                                                                $t(
                                                                    'leasings:addRepresentative'
                                                                )
                                                            }}</span>
                                                        </v-tooltip>
                                                    </template>
                                                    <v-card>
                                                        <v-container>
                                                            <v-row>
                                                                <v-col
                                                                    cols="12"
                                                                >
                                                                    <h3>
                                                                        {{
                                                                            `${$t(
                                                                                'leasings:additionRepresentative'
                                                                            )}`
                                                                        }}
                                                                    </h3>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col
                                                                    cols="12"
                                                                >
                                                                    <v-text-field
                                                                        dense
                                                                        v-model="
                                                                            getRepresentativeModal.name
                                                                        "
                                                                        :label="`${$t(
                                                                            'leasings:representativeName'
                                                                        )}`"
                                                                    >
                                                                    </v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col
                                                                    cols="12"
                                                                >
                                                                    <v-text-field
                                                                        dense
                                                                        v-model="
                                                                            getRepresentativeModal.lastname
                                                                        "
                                                                        :label="`${$t(
                                                                            'leasings:representativeLastname'
                                                                        )}`"
                                                                    >
                                                                    </v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col
                                                                    cols="12"
                                                                >
                                                                    <v-text-field
                                                                        dense
                                                                        v-model="
                                                                            getRepresentativeModal.email
                                                                        "
                                                                        :label="`${$t(
                                                                            'leasings:representativeEmail'
                                                                        )}`"
                                                                    >
                                                                    </v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col
                                                                    cols="12"
                                                                >
                                                                    <v-text-field
                                                                        dense
                                                                        v-model="
                                                                            getRepresentativeModal.phoneNumber
                                                                        "
                                                                        :label="`${$t(
                                                                            'leasings:representativePhoneNumber'
                                                                        )}`"
                                                                    >
                                                                    </v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col
                                                                    md="12"
                                                                    class="py-0 my-0 d-flex justify-end"
                                                                >
                                                                    <v-btn
                                                                        class="buttons buttons--cancel mr-2"
                                                                        @click="
                                                                            clearMiniModal()
                                                                        "
                                                                        >{{
                                                                            $t(
                                                                                'cancel'
                                                                            )
                                                                        }}</v-btn
                                                                    >
                                                                    <v-btn
                                                                        class="buttons buttons--add"
                                                                        :disabled="
                                                                            disabled
                                                                        "
                                                                        @click="
                                                                            additionRepresentative()
                                                                        "
                                                                        >{{
                                                                            $t(
                                                                                'add'
                                                                            )
                                                                        }}</v-btn
                                                                    >
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </v-card>
                                                </v-menu>
                                            </template>
                                        </v-combobox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="8">
                                         <v-select
                                             :label="`${$t(
                                                'leasings:leasingItem'
                                            )}*`"
                                            :items="getRegistryDictionaries.leasingItem
                                                    ? getRegistryDictionaries.leasingItem
                                                    : []"
                                            return-object
                                            item-value="field"
                                            v-model="
                                                getLeasingModal.leasingItem
                                            "
                                            :error-messages="
                                                getLeasingError.stepOne.fields
                                                    .leasingItem
                                            "
                                            ><template v-slot:item="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select>                                        
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content step="2">
                                <v-row class="mb-5">
                                    <v-col cols="6"
                                        ><v-menu
                                            v-model="startDate"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs,
                                                }"
                                            >
                                                <v-text-field
                                                    v-model="
                                                        getLeasingModal.startDate
                                                    "
                                                    :error-messages="
                                                        getLeasingError.stepTwo
                                                            .fields.startDate
                                                    "
                                                    clearable
                                                    @click:clear="
                                                        getLeasingModal.startDate = null
                                                    "
                                                    :label="`${$t(
                                                        'leasings:startDate'
                                                    )}*`"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="
                                                    getLeasingModal.startDate
                                                "
                                                @input="startDate = false"
                                            ></v-date-picker> </v-menu
                                    ></v-col>
                                    <v-col cols="6"
                                        ><v-menu
                                            v-model="endDate"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs,
                                                }"
                                            >
                                                <v-text-field
                                                    v-model="
                                                        getLeasingModal.endDate
                                                    "
                                                    :error-messages="
                                                        getLeasingError.stepTwo
                                                            .fields.endDate
                                                    "
                                                    clearable
                                                    @click:clear="
                                                        getLeasingModal.endDate = null
                                                    "
                                                    :label="`${$t(
                                                        'leasings:endDate'
                                                    )}*`"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="
                                                    getLeasingModal.endDate
                                                "
                                                @input="endDate = false"
                                            ></v-date-picker> </v-menu
                                    ></v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="3" class="mt-5">
                                        <money-field
                                            :label="`${$t(
                                                'leasings:netWorth'
                                            )}*`"
                                            v-model="getLeasingModal.netWorth"
                                            :error-messages="
                                                getLeasingError.stepOne.fields
                                                    .netWorth
                                            "
                                        >
                                        </money-field>
                                    </v-col>
                                    <v-col cols="3" class="mt-5">
                                        <money-field
                                            :label="`${$t(
                                                'leasings:monthWorth'
                                            )}*`"
                                            v-model="getLeasingModal.monthWorth"
                                            :error-messages="
                                                getLeasingError.stepOne.fields
                                                    .monthWorth
                                            "
                                        >
                                        </money-field>
                                    </v-col>
                                    <v-col cols="3" class="mt-5">
                                        <money-field
                                            :label="`${$t(
                                                'leasings:instalment'
                                            )}*`"
                                            v-model="getLeasingModal.instalment"
                                            :error-messages="
                                                getLeasingError.stepOne.fields
                                                    .instalment
                                            "
                                        >
                                        </money-field>
                                    </v-col>
                                    <v-col cols="3" class="mt-5">
                                        <money-field
                                            :label="`${$t(
                                                'leasings:nettoBuyout'
                                            )}*`"
                                            v-model="
                                                getLeasingModal.nettoBuyout
                                            "
                                            :error-messages="
                                                getLeasingError.stepOne.fields
                                                    .nettoBuyout
                                            "
                                        >
                                        </money-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field
                                            :label="`${$t(
                                                'leasings:otherCosts'
                                            )}`"
                                            v-model="getLeasingModal.otherCosts"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                            :label="`${$t(
                                                'leasings:description'
                                            )}`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                            v-model="
                                                getLeasingModal.description
                                            "
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            editable: true,
            startDate: '',
            contactId: '',
            disabled: false,
            hideContacts: true,
            isClient: false,
            endDate: '',
            showMenu: false,
            property: [
                { text: this.$t('leasings:car'), value: 'car' },
                {
                    text: this.$t('leasings:printDevices'),
                    value: 'printDevices',
                },
                { text: this.$t('leasings:other'), value: 'other' },
            ],
        }
    },
    methods: {
        ...mapActions([
            'fetchContactsInputs',
            'createRepresentative',
            'fetchRepresentativesInput',
        ]),
        text: item => item.name + item.lastname,
        ...mapMutations(['setContactsInput', 'setRepresentativesInput']),
        setContacts(search) {
            if (search.length >= 4) {
                this.setContactsInput({ search })
                this.fetchContactsInputs()
                this.hideContacts = false
            } else {
                this.getContactsInput.items = []
                this.hideContacts = true
            }
        },
        setRepresentatives(search) {
            this.setRepresentativesInput({ search })
            this.fetchRepresentativesInput({
                id: this.getLeasingModal.contact._id,
            })
        },
        assignID() {
            if (
                this.getLeasingModal.contact &&
                this.getLeasingModal.contact._id
            ) {
                this.getLeasingModal.representative = null
                this.fetchRepresentativesInput({
                    id: this.getLeasingModal.contact._id,
                })
            }
        },
        async additionRepresentative() {
            this.disabled = true
            let success = await this.createRepresentative({
                id: this.getLeasingModal.contact._id,
            })
            if (success) {
                this.clearMiniModal()
                this.assignID()
            }

            this.disabled = false
        },
        clearMiniModal() {
            this.showMenu = false
        },
    },

    computed: {
        ...mapGetters([
            'getLeasingError',
            'getLeasingStep',
            'getLeasingModal',
            'getContactsInput',
            'getRepresentativeInput',
            'getRepresentativeModal',
            'getRegistryDictionaries'
        ]),
        leasingItem: {
            get() {
                if (this.getRegistryDictionaries.property)
                    return this.getRegistryDictionaries.property.sort((a, b) =>
                        a.field > b.field ? 1 : -1
                    )
                else []
            },
        },
    },
    beforeMount() {
        // this.setContacts()
        this.assignID()
    },
}
</script>
