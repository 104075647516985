<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="3" xl="3" lg="3" md="5" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" class="d-flex flex-column">
                        <p
                            class="font-weight-bold d-flex flex-column headline pa-0 ma-0"
                        >
                            {{ getLeasingDetails.contact.name }}
                        </p>
                        <span class="mb-5">{{
                            getLeasingDetails.leasingNumber
                        }}</span>
                        <span
                            ><b>{{ `${$t('leasings:representative')}` }}:</b>
                            {{
                                $get(
                                    getLeasingDetails.representative,
                                    'name',
                                    ''
                                )
                            }}
                            {{
                                $get(
                                    getLeasingDetails.representative,
                                    'lastname',
                                    ''
                                )
                            }}</span
                        >
                        <span class="mt-5"
                            ><b>{{ `${$t('leasings:startDate')}` }}:</b>
                            {{
                                getLeasingDetails.startDate
                                    ? $moment(
                                          getLeasingDetails.startDate
                                      ).format(`DD-MM-YYYY`)
                                    : $t('global:empty')
                            }}</span
                        >
                        <span
                            ><b>{{ `${$t('leasings:endDate')}` }}:</b>
                            {{
                                getLeasingDetails.endDate
                                    ? $moment(getLeasingDetails.endDate).format(
                                          `DD-MM-YYYY`
                                      )
                                    : $t('global:empty')
                            }}
                        </span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6" xl="6" lg="8" md="12" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" md="12" class="pt-0">
                        <Separator :text="$t('leasings:leasingInformation')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-text-field
                            readonly
                            :label="`${$t('leasings:leasingNumber')}*`"
                            :value="getLeasingDetails.leasingNumber"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field
                            readonly
                            :label="`${$t('leasings:title')}*`"
                            :value="getLeasingDetails.title"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-text-field
                            readonly
                            :label="`${$t('leasings:contact.default')}*`"
                            :value="$get(getLeasingDetails.contact, 'name', '')"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field
                            readonly
                            :label="`${$t('leasings:representative')}`"
                            :value="`${$get(
                                getLeasingDetails.representative,
                                'name',
                                ''
                            )} ${$get(
                                getLeasingDetails.representative,
                                'lastname',
                                ''
                            )}`"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="8">
                        <v-select
                            readonly
                            append-icon=""
                            v-model="getLeasingDetails.leasingItem"
                            :items="[getLeasingDetails.leasingItem]"
                            dense
                            :label="$t('leasings:contractType')"
                            return-object
                            item-value="field"
                            ><template v-slot:item="data">
                                <span v-if="data.item.backgroundColor">
                                    <v-chip
                                        class="mx-2"
                                        :color="data.item.backgroundColor"
                                        small
                                        label
                                        :text-color="
                                            data.item.textColor
                                                ? data.item.textColor
                                                : '#4d4d4d'
                                        "
                                    >
                                        {{ data.item.field }}
                                    </v-chip>
                                </span>
                                <span class="truncate-list" v-else>
                                    {{ data.item.field }}
                                </span>
                            </template>
                            <template v-slot:selection="data">
                                <span v-if="data.item.backgroundColor">
                                    <v-chip
                                        class="mx-2"
                                        :color="data.item.backgroundColor"
                                        small
                                        label
                                        :text-color="
                                            data.item.textColor
                                                ? data.item.textColor
                                                : '#4d4d4d'
                                        "
                                    >
                                        {{ data.item.field }}
                                    </v-chip>
                                </span>
                                <span class="truncate-list" v-else>
                                    {{ data.item.field }}
                                </span>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <money-field
                            readonly
                            :label="`${$t('leasings:netWorth')}*`"
                            :value="getLeasingDetails.netWorth"
                        >
                        </money-field>
                    </v-col>
                    <v-col cols="3">
                        <money-field
                            readonly
                            :label="`${$t('leasings:monthWorth')}*`"
                            :value="getLeasingDetails.monthWorth"
                        >
                        </money-field>
                    </v-col>
                    <v-col cols="3">
                        <money-field
                            readonly
                            :label="`${$t('leasings:instalment')}*`"
                            :value="getLeasingDetails.instalment"
                        >
                        </money-field>
                    </v-col>
                    <v-col cols="3">
                        <money-field
                            readonly
                            :label="`${$t('leasings:nettoBuyout')}*`"
                            :value="getLeasingDetails.nettoBuyout"
                        >
                        </money-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field
                            readonly
                            :label="`${$t('leasings:startDate')}`"
                            :value="
                                getLeasingDetails.startDate
                                    ? $moment(
                                          getLeasingDetails.startDate
                                      ).format('YYYY-MM-DD')
                                    : ''
                            "
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            readonly
                            :label="`${$t('leasings:endDate')}`"
                            :value="
                                getLeasingDetails.endDate
                                    ? $moment(getLeasingDetails.endDate).format(
                                          'YYYY-MM-DD'
                                      )
                                    : ''
                            "
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field
                            readonly
                            :label="`${$t('leasings:otherCosts')}`"
                            :value="getLeasingDetails.otherCosts"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            :label="`${$t('leasings:description')}`"
                            rows="1"
                            auto-grow
                            dense
                            readonly
                            no-resize
                            :value="getLeasingDetails.description"
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters(['getLeasingDetails']),
    },
}
</script>
