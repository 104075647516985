<template>
    <v-container id="InnerTableContainer" class="py-0">
        <v-row>
            <v-col cols="12" md="12" class="py-0">
                <Table
                    :items="getLeasingStuffTable.items"
                    :headers="headers"
                    :length="getLeasingStuffTable.pages"
                    :page="getLeasingStuffTable.page"
                    @search="setSearch"
                    @search-fields="setSearchFields"
                    @change-page="setPage"
                    @sort="setSort"
                    id="InnerTable"
                    class="leasingInnerTable"
                    :defaultFields="getLeasingStuffSearchFields.searchFields"
                >
                    <Columns
                        slot="columns"
                        :items="getLeasingStuffTable.items"
                    />
                </Table>
                <Modal
                    :title="$t('leasings:additionLeasingStuff')"
                    :open="open"
                    :height="'640'"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        :action="'create'"
                        slot="buttons"
                        @closeModal="open = false"
                    />
                </Modal>
                <div
                    class="d-flex justify-end buttons--action"
                    v-if="checkPermissions()"
                >
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                small
                                v-if="add()"
                                @click="addLeasingStuff()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('leasings:addLeasingStuff') }}</span>
                    </v-tooltip>
                </div></v-col
            >
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Columns from './Table/Columns.vue'
import AEContent from './../LeasingStuff/Modal/Content.vue'
import Buttons from './../LeasingStuff/Modal/Buttons.vue'
export default {
    data() {
        return {
            open: false,
            headers: [
                {
                    text: this.$t('leasings:leasingStuffId'),
                    value: 'objectId.text',
                    width: '22%',
                },
                {
                    text: this.$t('leasings:leasingStuffName'),
                    value: 'name',
                    width: '20%',
                },
                {
                    text: this.$t('leasings:leasingStuffObjectType'),
                    value: 'objectType',
                    width: '20%',
                },
                {
                    text: this.$t('leasings:leasingStuffNotes'),
                    value: 'notes',
                    width: 'auto',
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '7%',
                    sortable: false,
                },
            ],
        }
    },
    components: {
        Columns,
        AEContent,
        Buttons,
    },
    computed: {
        ...mapGetters([
            'getLeasingTable',
            'getLeasingStuffSearchFields',
            'getLeasingRegistry',
            'getLeasingDetails',
            'getLeasingStuffTable',
            'getProfileDetails',
        ]),
    },
    methods: {
        ...mapActions([
            'fetchUsers',
            'fetchLeasingTable',
            'fetchLeasingStuffTable',
        ]),
        ...mapMutations([
            'clearLeasingStuffTable',
            'setLeasingStuffTable',
            'setLeasingStuffSearch',
            'clearLeasingStuffErrors',
            'clearLeasingStuffModal',
        ]),
        closeModal() {
            this.open = false
        },
        add() {
            return this.checkPermissions('CREATE')
        },
        async addLeasingStuff() {
            this.open = true
            this.clearLeasingStuffErrors()
            this.clearLeasingStuffModal()
        },
        setSearchFields(searchFields) {
            this.setLeasingStuffSearch(searchFields)
        },
        setSearch(search) {
            this.setLeasingStuffTable({ search, page: 1 })
            this.fetchLeasingStuffTable({ id: this.$route.params.id })
        },
        setPage(page) {
            this.setLeasingStuffTable({ page })
            this.fetchLeasingStuffTable({ id: this.$route.params.id })
        },
        setSort(sort) {
            this.setLeasingStuffTable({ sort })
            this.fetchLeasingStuffTable({ id: this.$route.params.id })
        },
        clearTable() {
            this.clearLeasingStuffTable()
            this.fetchLeasingStuffTable({ id: this.$route.params.id })
        },
        checkPermissions() {
            return (
                (this.getProfileDetails.role.registries.find(
                    e => e.url === 'leasings'
                ) &&
                    this.getProfileDetails.role.registries.find(
                        e => e.url === 'leasings'
                    ).operations &&
                    this.getProfileDetails.role.registries
                        .find(e => e.url === 'leasings')
                        .operations.includes('UPDATE')) ||
                this.getProfileDetails.isAdmin ||
                this.getProfileDetails.isSuperAdmin
            )
        },
    },
}
</script>
<style lang="scss">
#InnerTable {
    padding: 0;
    & > .row {
        & > div {
            box-shadow: 0 0 0 transparent !important;
            height: auto;
        }
    }
}
#InnerTable .central__table > .v-data-table__wrapper {
    height: calc(100vh - 460px) !important;
}
// .leasingInnerTable {
//     .v-data-table {
//     }
// }
#InnerTableContainer {
    .buttons--action {
        bottom: -50px !important;
        right: -2px !important;
    }
}
</style>
