<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper
                    v-model="getLeasingStuffError.step"
                    class="elevation-0"
                >
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () =>
                                        getLeasingStuffError.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('leasings:basicInformation')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content class="pb-0 pt-4" step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="
                                                $t('leasings:basicInformation')
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-combobox
                                            dense
                                            :label="`${$t(
                                                'leasings:leasingStuffId'
                                            )}*`"
                                            class="mb-3"
                                            v-model="
                                                getLeasingStuffModal.objectId
                                            "
                                            :hint="`${$t(
                                                'global:startWriting'
                                            )}`"
                                            @input="assignToTable"
                                            @keyup="
                                                searchStuff($event.target.value)
                                            "
                                            item-text="text"
                                            :items="searchItems"
                                            return-object
                                            :error-messages="
                                                getLeasingStuffError.stepOne
                                                    .fields.objectId
                                            "
                                            :readonly="edit"
                                            @click="onSelect"
                                        >
                                            <template v-slot:item="{ item }">
                                                {{ `${item.text}` }}
                                            </template>
                                            <template slot="prepend">
                                                <img
                                                    src="@/assets/img/global/search.png"
                                                    width="16"
                                                    height="16"
                                                    alt
                                                    class="mt-1"
                                                />
                                            </template>
                                        </v-combobox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6"
                                        ><v-text-field
                                            v-model="getLeasingStuffModal.name"
                                            readonly
                                            :label="`${$t(
                                                'leasings:leasingStuffName'
                                            )}`"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6"
                                        ><v-text-field
                                            v-model="
                                                getLeasingStuffModal.objectType
                                            "
                                            readonly
                                            :label="`${$t(
                                                'leasings:leasingStuffObjectType'
                                            )}`"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                            :label="`${$t(
                                                'leasings:leasingStuffNotes'
                                            )}`"
                                            rows="1"
                                            auto-grow
                                            no-resize
                                            v-model="getLeasingStuffModal.notes"
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: ['edit'],
    data() {
        return {
            searchItems: [],
        }
    },
    methods: {
        ...mapActions(['searchObject']),
        ...mapMutations(['clearLeasingStuffErrors']),
        onSelect() {
            this.clearLeasingStuffErrors()
            this.searchItems = []
        },
        assignToTable(data) {
            const { value } = data
            if (value?.vin) {
                this.getLeasingStuffModal.car = value._id
            } else {
                this.getLeasingStuffModal.device = value._id
            }
            this.getLeasingStuffModal.name =
                value.model && value.manufacturer
                    ? `${value.manufacturer} ${value.model.model}`
                    : typeof value.model === 'object'
                    ? value.model.model
                    : value.model
            this.getLeasingStuffModal.objectType =
                value.typeOfCar || value.deviceType || value.model?.deviceType
        },
        async searchStuff(data) {
            if (data.length >= 4) {
                const items = await this.searchObject({ data })
                this.searchItems = items?.map(el => {
                    return { text: el.sn ? el.sn : el.vin, value: el }
                })
            } else {
                this.searchItems = []
            }
        },
    },
    computed: {
        ...mapGetters([
            'getLeasingStuffModal',
            'getLeasingStuffError',
            'getCarsTable',
            'getDevicesTable',
        ]),
    },
}
</script>
