var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.getLeasingStep.step),callback:function ($$v) {_vm.$set(_vm.getLeasingStep, "step", $$v)},expression:"getLeasingStep.step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getLeasingError.stepOne.validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('leasings:basicInformation')))]),_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getLeasingError.stepOne.validation,
                            ],"editable":"","step":"2"}},[_vm._v(_vm._s(_vm.$t('leasings:other')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t(
                                                'leasings:leasingInformation'
                                            )}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":`${_vm.$t(
                                            'leasings:leasingNumber'
                                        )}*`,"error-messages":_vm.getLeasingError.stepOne.fields
                                                .leasingNumber},model:{value:(
                                            _vm.getLeasingModal.leasingNumber
                                        ),callback:function ($$v) {_vm.$set(_vm.getLeasingModal, "leasingNumber", $$v)},expression:"\n                                            getLeasingModal.leasingNumber\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":`${_vm.$t('leasings:title')}*`,"error-messages":_vm.getLeasingError.stepOne.fields
                                                .title},model:{value:(_vm.getLeasingModal.title),callback:function ($$v) {_vm.$set(_vm.getLeasingModal, "title", $$v)},expression:"getLeasingModal.title"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-combobox',{attrs:{"label":`${_vm.$t(
                                            'leasings:contact.default'
                                        )}*`,"hint":"Zacznij pisać...","hide-no-data":_vm.hideContacts,"items":_vm.getContactsInput.items,"item-text":"name","item-value":"_id","return-object":"","error-messages":_vm.getLeasingError.stepOne.fields
                                                .contact},on:{"keyup":function($event){return _vm.setContacts($event.target.value)},"input":function($event){return _vm.assignID()}},model:{value:(_vm.getLeasingModal.contact),callback:function ($$v) {_vm.$set(_vm.getLeasingModal, "contact", $$v)},expression:"getLeasingModal.contact"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-combobox',{attrs:{"label":`${_vm.$t(
                                            'leasings:representative'
                                        )}`,"item-text":_vm.text,"return-object":"","append-outer-icon":"mdi-account-plus","item-value":"_id","items":_vm.getRepresentativeInput.items},on:{"keyup":function($event){return _vm.setRepresentatives(
                                                $event.target.value
                                            )}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" "+_vm._s(data.item.lastname)+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" "+_vm._s(data.item.lastname)+" ")]}}]),model:{value:(
                                            _vm.getLeasingModal.representative
                                        ),callback:function ($$v) {_vm.$set(_vm.getLeasingModal, "representative", $$v)},expression:"\n                                            getLeasingModal.representative\n                                        "}},[_c('template',{slot:"append-outer"},[_c('v-menu',{attrs:{"close-on-content-click":false,"close-on-click":false,"max-width":"300","min-width":"300","origin":"center"},on:{"input":v =>
                                                        v ||
                                                        _vm.clearMiniModal()},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                        on: menu,
                                                    }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                                on: tooltip,
                                                            }){return [_c('v-btn',{attrs:{"small":"","icon":"","disabled":_vm.getLeasingModal.contact
                                                                        ? false
                                                                        : true}},[_c('v-icon',_vm._g({on:{"click":function($event){_vm.showMenu = true}}},{
                                                                        ...menu,
                                                                        ...tooltip,
                                                                    }),[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t( 'leasings:addRepresentative' )))])])]}}]),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-card',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(" "+_vm._s(`${_vm.$t( 'leasings:additionRepresentative' )}`)+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":`${_vm.$t(
                                                                        'leasings:representativeName'
                                                                    )}`},model:{value:(
                                                                        _vm.getRepresentativeModal.name
                                                                    ),callback:function ($$v) {_vm.$set(_vm.getRepresentativeModal, "name", $$v)},expression:"\n                                                                        getRepresentativeModal.name\n                                                                    "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":`${_vm.$t(
                                                                        'leasings:representativeLastname'
                                                                    )}`},model:{value:(
                                                                        _vm.getRepresentativeModal.lastname
                                                                    ),callback:function ($$v) {_vm.$set(_vm.getRepresentativeModal, "lastname", $$v)},expression:"\n                                                                        getRepresentativeModal.lastname\n                                                                    "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":`${_vm.$t(
                                                                        'leasings:representativeEmail'
                                                                    )}`},model:{value:(
                                                                        _vm.getRepresentativeModal.email
                                                                    ),callback:function ($$v) {_vm.$set(_vm.getRepresentativeModal, "email", $$v)},expression:"\n                                                                        getRepresentativeModal.email\n                                                                    "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":`${_vm.$t(
                                                                        'leasings:representativePhoneNumber'
                                                                    )}`},model:{value:(
                                                                        _vm.getRepresentativeModal.phoneNumber
                                                                    ),callback:function ($$v) {_vm.$set(_vm.getRepresentativeModal, "phoneNumber", $$v)},expression:"\n                                                                        getRepresentativeModal.phoneNumber\n                                                                    "}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0 my-0 d-flex justify-end",attrs:{"md":"12"}},[_c('v-btn',{staticClass:"buttons buttons--cancel mr-2",on:{"click":function($event){return _vm.clearMiniModal()}}},[_vm._v(_vm._s(_vm.$t( 'cancel' )))]),_c('v-btn',{staticClass:"buttons buttons--add",attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.additionRepresentative()}}},[_vm._v(_vm._s(_vm.$t( 'add' )))])],1)],1)],1)],1)],1)],1)],2)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-select',{attrs:{"label":`${_vm.$t(
                                            'leasings:leasingItem'
                                        )}*`,"items":_vm.getRegistryDictionaries.leasingItem
                                                ? _vm.getRegistryDictionaries.leasingItem
                                                : [],"return-object":"","item-value":"field","error-messages":_vm.getLeasingError.stepOne.fields
                                                .leasingItem},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(
                                            _vm.getLeasingModal.leasingItem
                                        ),callback:function ($$v) {_vm.$set(_vm.getLeasingModal, "leasingItem", $$v)},expression:"\n                                            getLeasingModal.leasingItem\n                                        "}})],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-row',{staticClass:"mb-5"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                on,
                                                attrs,
                                            }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"error-messages":_vm.getLeasingError.stepTwo
                                                        .fields.startDate,"clearable":"","label":`${_vm.$t(
                                                    'leasings:startDate'
                                                )}*`,"prepend-icon":"mdi-calendar","readonly":""},on:{"click:clear":function($event){_vm.getLeasingModal.startDate = null}},model:{value:(
                                                    _vm.getLeasingModal.startDate
                                                ),callback:function ($$v) {_vm.$set(_vm.getLeasingModal, "startDate", $$v)},expression:"\n                                                    getLeasingModal.startDate\n                                                "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},[_c('v-date-picker',{on:{"input":function($event){_vm.startDate = false}},model:{value:(
                                                _vm.getLeasingModal.startDate
                                            ),callback:function ($$v) {_vm.$set(_vm.getLeasingModal, "startDate", $$v)},expression:"\n                                                getLeasingModal.startDate\n                                            "}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                on,
                                                attrs,
                                            }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"error-messages":_vm.getLeasingError.stepTwo
                                                        .fields.endDate,"clearable":"","label":`${_vm.$t(
                                                    'leasings:endDate'
                                                )}*`,"prepend-icon":"mdi-calendar","readonly":""},on:{"click:clear":function($event){_vm.getLeasingModal.endDate = null}},model:{value:(
                                                    _vm.getLeasingModal.endDate
                                                ),callback:function ($$v) {_vm.$set(_vm.getLeasingModal, "endDate", $$v)},expression:"\n                                                    getLeasingModal.endDate\n                                                "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},[_c('v-date-picker',{on:{"input":function($event){_vm.endDate = false}},model:{value:(
                                                _vm.getLeasingModal.endDate
                                            ),callback:function ($$v) {_vm.$set(_vm.getLeasingModal, "endDate", $$v)},expression:"\n                                                getLeasingModal.endDate\n                                            "}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-5",attrs:{"cols":"3"}},[_c('money-field',{attrs:{"label":`${_vm.$t(
                                            'leasings:netWorth'
                                        )}*`,"error-messages":_vm.getLeasingError.stepOne.fields
                                                .netWorth},model:{value:(_vm.getLeasingModal.netWorth),callback:function ($$v) {_vm.$set(_vm.getLeasingModal, "netWorth", $$v)},expression:"getLeasingModal.netWorth"}})],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"3"}},[_c('money-field',{attrs:{"label":`${_vm.$t(
                                            'leasings:monthWorth'
                                        )}*`,"error-messages":_vm.getLeasingError.stepOne.fields
                                                .monthWorth},model:{value:(_vm.getLeasingModal.monthWorth),callback:function ($$v) {_vm.$set(_vm.getLeasingModal, "monthWorth", $$v)},expression:"getLeasingModal.monthWorth"}})],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"3"}},[_c('money-field',{attrs:{"label":`${_vm.$t(
                                            'leasings:instalment'
                                        )}*`,"error-messages":_vm.getLeasingError.stepOne.fields
                                                .instalment},model:{value:(_vm.getLeasingModal.instalment),callback:function ($$v) {_vm.$set(_vm.getLeasingModal, "instalment", $$v)},expression:"getLeasingModal.instalment"}})],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"3"}},[_c('money-field',{attrs:{"label":`${_vm.$t(
                                            'leasings:nettoBuyout'
                                        )}*`,"error-messages":_vm.getLeasingError.stepOne.fields
                                                .nettoBuyout},model:{value:(
                                            _vm.getLeasingModal.nettoBuyout
                                        ),callback:function ($$v) {_vm.$set(_vm.getLeasingModal, "nettoBuyout", $$v)},expression:"\n                                            getLeasingModal.nettoBuyout\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":`${_vm.$t(
                                            'leasings:otherCosts'
                                        )}`},model:{value:(_vm.getLeasingModal.otherCosts),callback:function ($$v) {_vm.$set(_vm.getLeasingModal, "otherCosts", $$v)},expression:"getLeasingModal.otherCosts"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":`${_vm.$t(
                                            'leasings:description'
                                        )}`,"rows":"1","auto-grow":"","no-resize":""},model:{value:(
                                            _vm.getLeasingModal.description
                                        ),callback:function ($$v) {_vm.$set(_vm.getLeasingModal, "description", $$v)},expression:"\n                                            getLeasingModal.description\n                                        "}})],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }