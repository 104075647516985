<template>
    <tbody>
        <tr v-for="(item, index) in items" :key="index">
            <!-- === === ID === === -->
            <td class="truncate">{{ item.objectId.text }}</td>

            <!-- === === NAME === === -->
            <td class="truncate">
                {{ item.name }}
            </td>
            <!-- === === OBJECTTYPE === === -->
            <td class="truncate">
                {{ item.objectType }}
            </td>
            <!-- === === NOTES === === -->
            <td class="truncate">
                {{ item.notes }}
            </td>

            <td class="d-flex align-center justify-end">
                <v-icon small v-if="hasFile(item.folders)"
                    >mdi-paperclip</v-icon
                >
                <v-menu bottom v-if="buttonAccess(item, 'UPDATE')||buttonAccess(item, 'DELETE')">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on">
                            <v-icon color="iconGray" small
                                >mdi-dots-vertical</v-icon
                            >
                        </v-btn>
                    </template>
                    <v-list dense>
                        <!-- === === EDIT === === -->
                        <v-list-item
                            v-if="buttonAccess(item, 'UPDATE')"
                            @click="openEditModal(item)"
                        >
                            <v-list-item-icon class="mr-0">
                                <v-icon color="grey" small>mdi-pencil</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Edytuj</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- === === REMOVE === === -->
                        <v-list-item
                            v-if="buttonAccess(item, 'DELETE')"
                            @click="openConfirmModal(item._id)"
                        >
                            <v-list-item-icon class="mr-0">
                                <v-icon color="red" small>mdi-delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Usuń</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-btn
                    small
                    @click="goTo(item)"
                    icon
                    v-if="
                        (Object.keys(item).includes('car') &&
                            checkPermissions('cars')) ||
                        (Object.keys(item).includes('device') &&
                            checkPermissions('devices'))
                    "
                >
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                color="secondary"
                                small
                                >mdi-arrow-right-thick</v-icon
                            >
                        </template>
                        <span>{{ $t('leasings:describe') }}</span>
                    </v-tooltip>
                </v-btn>
                <v-btn v-else small disabled icon></v-btn>
            </td>
        </tr>
        <ConfirmModal
            :openConfirm="openConfirm"
            @close-confirm="closeConfirmModal"
        >
            <div slot="main"><ConfirmContent /></div>
            <div slot="buttons">
                <div class="d-flex justify-center">
                    <v-btn
                        color="rgb(117, 117, 117)"
                        width="110"
                        height="40"
                        @click="closeConfirmModal()"
                        class="white--text mx-2"
                        >{{ $t('global:cancel') }}</v-btn
                    >
                    <v-btn
                        width="110"
                        height="40"
                        color="#445d75"
                        class="white--text mx-2"
                        @click="deleteItem()"
                        >{{ $t('global:remove') }}</v-btn
                    >
                </div>
            </div>
        </ConfirmModal>
        <Modal
            :title="$t('leasings:editLeasingStuff')"
            :open="openEdit"
            :height="'640'"
            v-on:close="closeEditModal"
        >
            <AEContent slot="AEContent" edit />
            <Buttons
                :action="'edit'"
                slot="buttons"
                @closeModal="openEdit = false"
            />
        </Modal>
    </tbody>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ConfirmContent from '../ConfirmModal/ConfirmContent.vue'
import AEContent from './../../LeasingStuff/Modal/Content.vue'
import Buttons from './../../LeasingStuff/Modal/Buttons.vue'
export default {
    props: ['items'],
    data: () => ({
        openConfirm: false,
        removeItemID: '',
        openEdit: false,
    }),
    components: {
        ConfirmContent,
        AEContent,
        Buttons,
    },
    computed: {
        ...mapGetters(['getProfileDetails']),
    },
    methods: {
        ...mapActions(['deleteLeasingStuff']),
        ...mapMutations([
            'setLeasingStuffModal',
            'clearLeasingStuffModal',
            'clearLeasingStuffErrors',
        ]),
        openConfirmModal(id) {
            this.removeItemID = id
            this.openConfirm = true
        },
        closeConfirmModal() {
            this.openConfirm = false
        },

        openEditModal(item) {
            this.openEdit = true
            this.clearLeasingStuffModal()
            this.clearLeasingStuffErrors()
            this.setLeasingStuffModal(item)
            //funkcja do edytowania
        },

        closeEditModal() {
            this.openEdit = false
        },

        async deleteItem() {
            this.deleteLeasingStuff({
                id: this.$route.params.id,
                itemId: this.removeItemID,
            })
            this.openConfirm = false
        },
        goTo(item) {
            if (
                Object.keys(item).includes('car') &&
                this.checkPermissions('cars')
            ) {
                this.$router.push(`/cars/${item.car._id}`)
            }
            if (
                Object.keys(item).includes('device') &&
                this.checkPermissions('devices')
            ) {
                this.$router.push(`/devices/${item.device._id}`)
            }
        },
        checkPermissions(url, perrmisions = 'READ') {         
            return (
                this.getProfileDetails.role.registries
                    .find(e => e.url === url)
                    ?.operations.includes(perrmisions) ||
                this.getProfileDetails.isAdmin ||
                this.getProfileDetails.isSuperAdmin
            )
        },
        buttonAccess(item, perrmisions) {
            return (
                (Object.keys(item).includes('device') &&
                    this.checkPermissions('devices', perrmisions)) ||
                (Object.keys(item).includes('car') &&
                    this.checkPermissions('cars', perrmisions))
            )
        },
    }
}
</script>
