<template>
    <div>
        <v-btn
            @click="action == 'edit' ? edit() : save()"
            :disabled="disabled"
            class="buttons buttons--add"
        >
            {{ action == 'edit' ? $t('global:edit') : $t('global:save') }}
        </v-btn>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        action: {
            type: String,
        },
    },
    data() {
        return {
            disabled: false,
        }
    },
    computed: {
        ...mapGetters(['getLeasingStuffModal', 'getLeasingStuffError']),
    },
    methods: {
        ...mapActions(['createLeasingStuff', 'editLeasingStuff']),
        ...mapMutations(['setLeasingStuffError']),
        async save() {
            this.disabled = true
            if (this.getLeasingStuffModal.objectId?.value) {
                if (!this.getLeasingStuffModal.objectId.value?.leasing&&!this.getLeasingStuffModal.objectId.value?.anotherDevice?.leasingAgreement) {
                    let success = await this.createLeasingStuff({
                        id: this.$route.params.id,
                    })
                    if (success) this.$emit('closeModal')
                } else {
                    this.getLeasingStuffError.stepOne.fields.objectId = this.$t(
                        'leasings:uniqueValue'
                    )
                }
            } else {
                this.getLeasingStuffError.stepOne.fields.objectId = this.$t(
                    'global:errorFields'
                )
            }
            this.disabled = false
        },
        async edit() {
            this.disabled = true
            if (this.getLeasingStuffModal.objectId?.value) {
                let success = await this.editLeasingStuff({
                    id: this.$route.params.id,
                })
                if (success) this.$emit('closeModal')
            } else {
                this.getLeasingStuffError.stepOne.fields.objectId = this.$t(
                    'global:errorFields'
                )
            }
            this.disabled = false
        },
    },
}
</script>
