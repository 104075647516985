var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.getLeasingStuffError.step),callback:function ($$v) {_vm.$set(_vm.getLeasingStuffError, "step", $$v)},expression:"getLeasingStuffError.step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () =>
                                    _vm.getLeasingStuffError.stepOne.validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('leasings:basicInformation')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{staticClass:"pb-0 pt-4",attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('leasings:basicInformation')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-combobox',{staticClass:"mb-3",attrs:{"dense":"","label":`${_vm.$t(
                                            'leasings:leasingStuffId'
                                        )}*`,"hint":`${_vm.$t(
                                            'global:startWriting'
                                        )}`,"item-text":"text","items":_vm.searchItems,"return-object":"","error-messages":_vm.getLeasingStuffError.stepOne
                                                .fields.objectId,"readonly":_vm.edit},on:{"input":_vm.assignToTable,"keyup":function($event){return _vm.searchStuff($event.target.value)},"click":_vm.onSelect},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_vm._v(" "+_vm._s(`${item.text}`)+" ")]}}]),model:{value:(
                                            _vm.getLeasingStuffModal.objectId
                                        ),callback:function ($$v) {_vm.$set(_vm.getLeasingStuffModal, "objectId", $$v)},expression:"\n                                            getLeasingStuffModal.objectId\n                                        "}},[_c('template',{slot:"prepend"},[_c('img',{staticClass:"mt-1",attrs:{"src":require("@/assets/img/global/search.png"),"width":"16","height":"16","alt":""}})])],2)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"readonly":"","label":`${_vm.$t(
                                            'leasings:leasingStuffName'
                                        )}`},model:{value:(_vm.getLeasingStuffModal.name),callback:function ($$v) {_vm.$set(_vm.getLeasingStuffModal, "name", $$v)},expression:"getLeasingStuffModal.name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"readonly":"","label":`${_vm.$t(
                                            'leasings:leasingStuffObjectType'
                                        )}`},model:{value:(
                                            _vm.getLeasingStuffModal.objectType
                                        ),callback:function ($$v) {_vm.$set(_vm.getLeasingStuffModal, "objectType", $$v)},expression:"\n                                            getLeasingStuffModal.objectType\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":`${_vm.$t(
                                            'leasings:leasingStuffNotes'
                                        )}`,"rows":"1","auto-grow":"","no-resize":""},model:{value:(_vm.getLeasingStuffModal.notes),callback:function ($$v) {_vm.$set(_vm.getLeasingStuffModal, "notes", $$v)},expression:"getLeasingStuffModal.notes"}})],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }