<template>
    <v-container>
        <Header
            :registryStatus="getLeasingStatuses"
            :recordIndex="getLeasingDetails.index"
            :registryTitle="getLeasingRegistry.name"
            :registryUrl="'leasings'"
            :actualStatus="getLeasingDetails.status"
            @status-changed="changeStatus"
            :avatarData="getLeasingDetails.createdBy"
        />
        <Main :items="items" class="p-relative">
            <v-tab-item
                v-for="item in items"
                :key="item.id"
                slot="tabItems"
                :id="item.id"
            >
                <component :is="item.component"></component>
            </v-tab-item>
            <div slot="information">
                <Information />
                <Modal
                    :title="$t('leasings:editionLeasing')"
                    :open="open"
                    :height="'520'"
                    v-on:close="closeModal()"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        @closeModal="open = false"
                        :action="'edit'"
                        slot="buttons"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--details">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                v-on="on"
                                v-if="edit()"
                                small
                                @click="editLeasing()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('leasings:editLeasing') }}</span>
                    </v-tooltip>
                </div>
            </div>
        </Main>
    </v-container>
</template>
<script>
import store from './../../../store/index'
import LeasingStuff from './../../../components/Registries/Leasing/Tabs/LeasingStuff/Central.vue'
import Information from './../../../components//Registries/Leasing/Details/Information'
import Buttons from './../../../components//Registries/Leasing/Modal/Buttons'
import AEContent from './../../../components//Registries/Leasing/Modal/Content'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    i18nOptions: { namespaces: ['global'] },
    components: { Information, Buttons, AEContent },
    data() {
        return {
            open: false,
            items: [
                {
                    name: this.$i18n.t('leasings:leasingStuff'),
                    id: 'leasingStuff',
                    icon: 'mdi-format-list-bulleted',
                    component: LeasingStuff,
                },
            ],
        }
    },
    computed: {
        ...mapGetters([
            'getLeasingStatuses',
            'getLeasingDetails',
            'getLeasingStep',
            'getLeasingRegistry',
        ]),
    },
    methods: {
        ...mapMutations([
            'clearLeasingErrors',
            'setLeasingModal',
            'clearRepresentativesInput',
            'clearContactInput',
        ]),
        ...mapActions(['fetchLeasing', 'updateLeasingStatus']),
        async editLeasing() {
            this.open = true
            this.clearLeasingErrors()
            this.setLeasingModal()
        },
        edit() {
            return this.checkPermissions('UPDATE')
        },
        changeStatus(status) {
            this.updateLeasingStatus({
                status,
                id: this.getLeasingDetails._id,
            })
        },
        closeModal() {
            this.clearLeasingErrors()
            this.clearRepresentativesInput()
            this.clearContactInput()
            this.open = false
            this.getLeasingStep.step = 1
        },
    },
    beforeRouteEnter(to, from, next) {
        store.commit('clearLeasingStuffTable')
        store.dispatch('fetchDevicesTable')
        store.dispatch('fetchCarsTable')
        store.dispatch('fetchLeasingStuffTable', { id: to.params.id, next })
    },
}
</script>
